const nameList = [
  "Time",
  "Past",
  "Future",
  "Dev",
  "Fly",
  "Flying",
  "Soar",
  "Soaring",
  "Power",
  "Falling",
  "Fall",
  "Jump",
  "Cliff",
  "Mountain",
  "Rend",
  "Red",
  "Blue",
  "Green",
  "Yellow",
  "Gold",
  "Demon",
  "Demonic",
  "Panda",
  "Cat",
  "Kitty",
  "Kitten",
  "Zero",
  "Memory",
  "Trooper",
  "XX",
  "Bandit",
  "Fear",
  "Light",
  "Glow",
  "Tread",
  "Deep",
  "Deeper",
  "Deepest",
  "Mine",
  "Your",
  "Worst",
  "Enemy",
  "Hostile",
  "Force",
  "Video",
  "Game",
  "Donkey",
  "Mule",
  "Colt",
  "Cult",
  "Cultist",
  "Magnum",
  "Gun",
  "Assault",
  "Recon",
  "Trap",
  "Trapper",
  "Redeem",
  "Code",
  "Script",
  "Writer",
  "Near",
  "Close",
  "Open",
  "Cube",
  "Circle",
  "Geo",
  "Genome",
  "Germ",
  "Spaz",
  "Shot",
  "Echo",
  "Beta",
  "Alpha",
  "Gamma",
  "Omega",
  "Seal",
  "Squid",
  "Money",
  "Cash",
  "Lord",
  "King",
  "Duke",
  "Rest",
  "Fire",
  "Flame",
  "Morrow",
  "Break",
  "Breaker",
  "Numb",
  "Ice",
  "Cold",
  "Rotten",
  "Sick",
  "Sickly",
  "Janitor",
  "Camel",
  "Rooster",
  "Sand",
  "Desert",
  "Dessert",
  "Hurdle",
  "Racer",
  "Eraser",
  "Erase",
  "Big",
  "Small",
  "Short",
  "Tall",
  "Sith",
  "Bounty",
  "Hunter",
  "Cracked",
  "Broken",
  "Sad",
  "Happy",
  "Joy",
  "Joyful",
  "Crimson",
  "Destiny",
  "Deceit",
  "Lies",
  "Lie",
  "Honest",
  "Destined",
  "Bloxxer",
  "Hawk",
  "Eagle",
  "Hawker",
  "Walker",
  "Zombie",
  "Sarge",
  "Capt",
  "Captain",
  "Punch",
  "One",
  "Two",
  "Uno",
  "Slice",
  "Slash",
  "Melt",
  "Melted",
  "Melting",
  "Fell",
  "Wolf",
  "Hound",
  "Legacy",
  "Sharp",
  "Dead",
  "Mew",
  "Chuckle",
  "Bubba",
  "Bubble",
  "Sandwich",
  "Smasher",
  "Extreme",
  "Multi",
  "Universe",
  "Ultimate",
  "Death",
  "Ready",
  "Monkey",
  "Elevator",
  "Wrench",
  "Grease",
  "Head",
  "Theme",
  "Grand",
  "Cool",
  "Kid",
  "Boy",
  "Girl",
  "Vortex",
  "Paradox",
];

const comments = [
  "Wow, I learned so much from this video!",
  "This content is top-notch, keep it up!",
  "Thanks for making complex topics easy to understand!",
  "I never thought about this perspective before – great insights!",
  "Your videos always brighten my day, thank you!",
  "Fantastic explanation, I appreciate the effort you put into this.",
  "This is exactly what I was looking for, very helpful!",
  "Your channel deserves way more subscribers, amazing content!",
  "I've been binge-watching your videos, can't get enough!",
  "You make learning enjoyable, thank you for that!",
  "I wish more creators were as informative as you are.",
  "The way you break down concepts is so clear and concise!",
  "Your video quality is excellent, and the information is even better!",
  "I've recommended your channel to all my friends – it's that good!",
  "Your dedication to quality really shows in your videos.",
  "This is the kind of content that makes YouTube worthwhile.",
  "I love how you make even the most complex topics interesting!",
  "Subscribed and notifications turned on – never want to miss your uploads!",
  "Your energy is infectious – makes learning so much fun!",
  "This video deserves to go viral, it's that good!",
  "Great video!",
  "Awesome content!",
  "So helpful!",
  "Amazing!",
  "Love it!",
  "Spot on!",
  "Exactly what I needed!",
  "Brilliant!",
  "You nailed it!",
  "Short and sweet, love it!",
  "This is gold!",
  "Perfect!",
  "Thanks a bunch!",
  "Mind blown!",
  "Can't get enough!",
  "Incredible!",
  "You rock!",
  "Genius!",
  "Spectacular!",
  "Top-notch!",
];

const generateRandomName = () => {
  const finalName = nameList[Math.floor(Math.random() * nameList.length)];
  return finalName;
};

const generateRandomComment = () => {
  const randomComment = comments[Math.floor(Math.random() * comments.length)];
  return randomComment;
};

export { generateRandomName, generateRandomComment };
